import type { FC } from "react"
import {
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"

import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import WalletButton from "web3/components/WalletButton"
import { useSignerStore } from "web3/providers/SignerProvider"
import { useConnectWallet } from "web3/hooks/useConnectWallet"
import { chainIdToChainReference } from "web3/helpers/transformers"
import {
  getWalletConnectSupportedChains,
  getWalletConnectSupportedTestnetChains,
} from "web3/helpers/walletconnect"
import { ConnectorId } from "web3/constants/connectors"

const WalletConnectChainSelector: FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { setChainIdForSigner } = useSignerStore()
  const { activateEVMWallet } = useConnectWallet()

  const wcSupportedChains = getWalletConnectSupportedChains()
  const wcSupportedChainsTestnet = getWalletConnectSupportedTestnetChains()

  return (
    <Stack spacing={4}>
      <Text textStyle="md">Please select a network for WalletConnect:</Text>
      <Tabs colorScheme="purple">
        <TabList>
          <Tab>Mainnets</Tab>
          <Tab>Testnets</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Stack spacing={2}>
              {wcSupportedChains.map((chain) => {
                const networkIcon = GenericNetworkIcon(chain)

                return (
                  <WalletButton
                    key={`${chain.id}--${chain.mediumName}`}
                    icon={
                      networkIcon ? (
                        <Icon as={networkIcon} height={8} width={8} />
                      ) : null
                    }
                    walletName={chain.mediumName}
                    onClick={() => {
                      setChainIdForSigner(chainIdToChainReference(chain.id))
                      onClick()
                      activateEVMWallet(
                        ConnectorId.WalletConnect,
                        chainIdToChainReference(chain.id),
                      )
                    }}
                  />
                )
              })}
            </Stack>
          </TabPanel>
          <TabPanel>
            <Stack spacing={2}>
              {wcSupportedChainsTestnet.map((chain) => {
                const networkIcon = GenericNetworkIcon(chain)

                return (
                  <WalletButton
                    key={`${chain.id}--${chain.mediumName}`}
                    icon={
                      networkIcon ? (
                        <Icon as={networkIcon} height={8} width={8} />
                      ) : null
                    }
                    walletName={chain.mediumName}
                    onClick={() => {
                      setChainIdForSigner(chainIdToChainReference(chain.id))
                      onClick()
                      activateEVMWallet(
                        ConnectorId.WalletConnect,
                        chainIdToChainReference(chain.id),
                      )
                    }}
                  />
                )
              })}
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default WalletConnectChainSelector
