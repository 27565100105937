import { useCallback } from "react"
import { useConnect } from "wagmi"
import { InjectedConnector } from "wagmi/connectors/injected"
import { useWallet } from "@solana/wallet-adapter-react"
import type { WalletName } from "@solana/wallet-adapter-base"

import { useModals } from "common/hooks/useModals"
import { useToast } from "common/hooks/useToast"
import { useSignerStore } from "web3/providers/SignerProvider"

type Values = {
  activateMetamask: () => void
  activateRabby: () => void
  activateCoinbaseWallet: () => void
  activateWalletConnect: (chainId?: number) => Promise<boolean>
  activateBraveWallet: () => void
  activateGenericInjected: () => void
  activateEVMWallet: (connectorId?: string, chainId?: number) => void
  activateSolanaWallet: (
    walletName: WalletName<string>,
  ) => Promise<void | boolean>
}

const useConnectWallet = (): Values => {
  const { setEthersSigner, signer } = useSignerStore()
  const { toast } = useToast()
  const { walletSelection } = useModals()
  const { connect, connectors } = useConnect({
    connector: new InjectedConnector({
      options: {
        shimDisconnect: true,
      },
    }),
    onSuccess: async (data) => {
      if (data.connector && !signer) {
        const signer = await data.connector.getSigner()
        setEthersSigner(signer)
      }
    },
  })
  const { select, connected, publicKey, disconnect } = useWallet()

  const activateMetamask = (): boolean => {
    try {
      if (!window?.ethereum) throw new Error("No MetaMask installed")
      connect({ connector: connectors[0] })

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "MetaMask has failed",
        description: (error as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateRabby = (): boolean => {
    try {
      if (!window?.ethereum?.isRabby) throw new Error("No Rabby installed")
      connect({ connector: connectors[4] })

      return true
    } catch (err) {
      toast({
        status: "error",
        title: "Rabby has failed",
        description: (err as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateBraveWallet = (): boolean => {
    try {
      if (!window?.ethereum?.isBraveWallet)
        throw new Error("No Brave wallet installed")
      connect({ connector: connectors[4] })

      return true
    } catch (err) {
      toast({
        status: "error",
        title: "Brave wallet has failed",
        description: (err as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateGenericInjected = (): boolean => {
    try {
      if (!window?.ethereum) throw new Error("No wallet installed")
      connect({ connector: connectors[4] })

      return true
    } catch (err) {
      toast({
        status: "error",
        title: "Wallet has failed",
        description: (err as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateWalletConnect = async (chainId?: number): Promise<boolean> => {
    try {
      connect({ connector: connectors[0], chainId })
      walletSelection.onClose()

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "WalletConnect has failed",
        description: (error as Error)?.message,
      })

      return false
    }
  }

  const activateCoinbaseWallet = (): boolean => {
    try {
      if (!window?.ethereum) throw new Error("No Coinbase Wallet installed")
      connect({ connector: connectors[2] })

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "Coinbase Wallet has failed",
        description: (error as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateEVMWallet = async (
    connectorId?: string,
    chainId?: number,
  ): Promise<boolean> => {
    if (!connectorId) {
      return false
    }

    // if solana is connected, disconnect from it before connecting to evm
    if (connected && publicKey) {
      await disconnect()
    }

    try {
      const connector = connectors.find((c) => c.id === connectorId)
      connect({ connector, chainId })

      return true
    } catch (error) {
      toast({
        status: "error",
        title: "Connecting to wallet failed",
        description: (error as Error)?.message,
      })

      return false
    } finally {
      walletSelection.onClose()
    }
  }

  const activateSolanaWallet = useCallback(
    async (walletName: WalletName<string>): Promise<boolean> => {
      try {
        select(walletName)

        return true
      } catch (error) {
        toast({
          status: "error",
          title: "Selecting wallet failed",
          description: (error as Error)?.message,
        })

        return false
      } finally {
        walletSelection.onClose()
      }
    },
    [select, toast, walletSelection],
  )

  return {
    activateMetamask,
    activateRabby,
    activateCoinbaseWallet,
    activateWalletConnect,
    activateBraveWallet,
    activateGenericInjected,
    activateEVMWallet,
    activateSolanaWallet,
  }
}

export { useConnectWallet }
