type MultichainWallet = {
  name: string
  key: string
  eip6963Config: { rdns: string }
  sol: { windowLocator: string[] }
}

// if a wallet is able to be used on solana and evm, we need to record it here
export const multichainWallets: Record<string, MultichainWallet> = {
  backpack: {
    name: "Backpack",
    key: "backpack",
    eip6963Config: {
      rdns: "app.backpack",
    },
    sol: {
      windowLocator: ["backpack"],
    },
  },
  phantom: {
    name: "Phantom",
    key: "phantom",
    eip6963Config: {
      rdns: "app.phantom",
    },
    sol: {
      windowLocator: ["phantom.solana"],
    },
  },
  brave: {
    name: "Brave Wallet",
    key: "brave",
    eip6963Config: {
      rdns: "com.brave.wallet",
    },
    sol: {
      windowLocator: ["braveSolana"],
    },
  },
}
