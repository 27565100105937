import { useCallback } from "react"
import invariant from "tiny-invariant"
import bs58 from "bs58"
import { useWallet } from "@solana/wallet-adapter-react"

import { SignInType } from "query/graphql"
import { fetchNonce } from "common/helpers/fetch"
import { useLogin } from "session/hooks/useLogin"
import { useToast } from "common/hooks/useToast"

type SolanaSignInInput = {
  /**
   * Optional EIP-4361 Domain.
   * If not provided, the wallet must determine the Domain to include in the message.
   */
  readonly domain?: string

  /**
   * Optional EIP-4361 Address.
   * If not provided, the wallet must determine the Address to include in the message.
   */
  readonly address?: string

  /**
   * Optional EIP-4361 Statement.
   * If not provided, the wallet must not include Statement in the message.
   */
  readonly statement?: string

  /**
   * Optional EIP-4361 URI.
   * If not provided, the wallet must not include URI in the message.
   */
  readonly uri?: string

  /**
   * Optional EIP-4361 Version.
   * If not provided, the wallet must not include Version in the message.
   */
  readonly version?: string

  /**
   * Optional EIP-4361 Chain ID.
   * If not provided, the wallet must not include Chain ID in the message.
   */
  readonly chainId?: string

  /**
   * Optional EIP-4361 Nonce.
   * If not provided, the wallet must not include Nonce in the message.
   */
  readonly nonce?: string

  /**
   * Optional EIP-4361 Issued At.
   * If not provided, the wallet must not include Issued At in the message.
   */
  readonly issuedAt?: string

  /**
   * Optional EIP-4361 Expiration Time.
   * If not provided, the wallet must not include Expiration Time in the message.
   */
  readonly expirationTime?: string

  /**
   * Optional EIP-4361 Not Before.
   * If not provided, the wallet must not include Not Before in the message.
   */
  readonly notBefore?: string

  /**
   * Optional EIP-4361 Request ID.
   * If not provided, the wallet must not include Request ID in the message.
   */
  readonly requestId?: string

  /**
   * Optional EIP-4361 Resources.
   * If not provided, the wallet must not include Resources in the message.
   */
  readonly resources?: readonly string[]
}

function createSignInMessageText(input: SolanaSignInInput): string {
  let message = `${input.domain} wants you to sign in with your Solana account:\n`
  message += `${input.address}`

  if (input.statement) {
    message += `\n\n${input.statement}`
  }

  const fields: string[] = []

  if (input.uri) {
    fields.push(`URI: ${input.uri}`)
  }

  if (input.version) {
    fields.push(`Version: ${input.version}`)
  }

  if (input.chainId) {
    fields.push(`Chain ID: ${input.chainId}`)
  }

  if (input.nonce) {
    fields.push(`Nonce: ${input.nonce}`)
  }

  if (input.issuedAt) {
    fields.push(`Issued At: ${input.issuedAt}`)
  }

  if (input.expirationTime) {
    fields.push(`Expiration Time: ${input.expirationTime}`)
  }

  if (input.notBefore) {
    fields.push(`Not Before: ${input.notBefore}`)
  }

  if (input.requestId) {
    fields.push(`Request ID: ${input.requestId}`)
  }

  if (input.resources) {
    fields.push(`Resources:`)
    for (const resource of input.resources) {
      fields.push(`- ${resource}`)
    }
  }

  if (fields.length) {
    message += `\n\n${fields.join("\n")}`
  }

  return message
}

export function useSiws() {
  const { signMessage } = useWallet()
  const { login } = useLogin()
  const { toast } = useToast()

  const signInWithSolana = useCallback(
    async ({ address }: { address: string }) => {
      const nonceResponse = await fetchNonce()

      invariant(nonceResponse, '"nonce" must be defined')

      const { nonce, expirationTime, issuedAt, nonceToken } = nonceResponse

      try {
        if (!signMessage) {
          throw new Error("unable to sign in")
        }

        const signInInput = createSignInMessageText({
          domain: window.location.host,
          address,
          uri: process.env.NEXT_PUBLIC_TALLY_SIWE_URI,
          nonce,
          issuedAt,
          expirationTime,
        })
        const messageBytes = new TextEncoder().encode(signInInput)
        const signature = await signMessage(messageBytes)
        const b64Message = Buffer.from(messageBytes).toString("base64")

        login(
          b64Message,
          bs58.encode(signature as Uint8Array),
          nonceToken,
          SignInType.Solana,
        )
      } catch (err) {
        toast({
          status: "warning",
          title: "Something went wrong",
          description: "Something went wrong signing in",
        })
      }
    },
    [signMessage, login, toast],
  )

  return {
    signInWithSolana,
  }
}
