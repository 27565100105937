import type {
  Chain as ChainQuery,
  NonceQuery,
  NonceQueryVariables,
} from "query/graphql"
import { NonceDocument } from "query/graphql"
import { getChainIdParams } from "web3/helpers/chainId"
import chains from "web3/constants/chains"
import type { ChainId } from "web3/constants/chains"
import { GrpcErrorType, fetcher } from "common/helpers/fetcher"

type Chain = Pick<
  ChainQuery,
  | "id"
  | "layer1Id"
  | "name"
  | "mediumName"
  | "shortName"
  | "blockTime"
  | "isTestnet"
  | "svg"
  | "nativeCurrency"
  | "chain"
  | "tenderlySupport"
  | "useLayer1VotingPeriod"
  | "blockExplorer"
  | "isDeprecated"
  | "features"
>

export const fetchChains = async (): Promise<Chain[]> => {
  const byChainReference = (a: Chain, b: Chain): number => {
    // this is ugly, but types are incorrect
    // https://linear.app/withtally/issue/TALLY-3470/rework-types-for-chains-query
    if (!a || !b) return 1

    const aReference = getChainIdParams(a.id).reference
    const bReference = getChainIdParams(b.id).reference

    return aReference > bReference ? 1 : -1
  }

  const _chains = Object.values(chains).map((chain) => ({
    id: chain.id,
    layer1Id: Boolean(chain.layer1Id) ? chain.layer1Id : null,
    name: chain.name,
    mediumName: chain.mediumName,
    shortName: chain.shortName,
    blockTime: chain.blockTime,
    isTestnet: chain.isTestnet,
    svg: Boolean(chain.svg) ? chain.svg : "",
    nativeCurrency: chain.nativeCurrency,
    chain: chain.chain,
    blockExplorer: Boolean(chain.blockExplorer) ? chain.blockExplorer : null,
    tenderlySupport: chain.tenderlySupport,
    useLayer1VotingPeriod: chain.useLayer1VotingPeriod,
    isDeprecated: chain.isDeprecated,
    features: chain.features,
  }))
  const chainsByReference = _chains ? _chains?.sort(byChainReference) : []

  return chainsByReference
}

export const getChainMediumName = (chainId: ChainId) => {
  const chainData = chains[chainId]

  return chainData ? chainData.mediumName : chainId
}

export const fetchNonce = async () => {
  const response = await fetcher.gql<NonceQuery, NonceQueryVariables>({
    query: NonceDocument,
    omittedErrors: [GrpcErrorType.NotFound],
    onError: (error) => console.log(error),
  })

  return Promise.resolve(response?.nonce)
}
