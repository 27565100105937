import { abbreviate } from "@pqt/abbreviate"
import { BigNumber } from "@ethersproject/bignumber"

export const isNumber = (value: unknown): boolean => Number.isInteger(value)

export const limitNumberDecimals = (number: number, precision = 2): number => {
  if (number < 0.0000001) {
    return 0
  }

  const [integer, decimals] = String(number).split(".")

  if (!decimals) return Number(integer)

  const decimalsToUse = decimals.slice(0, precision)

  return Number(integer + "." + decimalsToUse)
}

export const formatNumberWithPrecisionThreshold = (
  number: number,
  precision = 2,
): string => {
  if (number === 0) return "0"

  const threshold = Number("0." + "0".repeat(precision - 1) + "1")

  if (number < threshold) {
    return `< ${threshold.toFixed(precision)}`
  }

  const [integer, decimals] = String(number).split(".")

  if (!decimals) return integer

  const decimalsToUse = decimals.slice(0, precision)

  return integer + "." + decimalsToUse
}

export const labelNumber = (number: number): string => {
  if (number > 1000) return abbreviate(number, 2)
  if (number === 0) return String(0)
  if (number > 0.01) return String(limitNumberDecimals(number))

  return "< 0.01"
}

export const removeTrailingZeros = (str: string): string => {
  // Convert a string "200.0" to just "200"
  return parseFloat(str).toString()
}

export const getNumberSuffix = (number: number): string => {
  const lastDigit = number % 10

  if (number >= 11 && number <= 13) {
    return "th"
  }

  switch (lastDigit) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

export const truncateToMaxDecimals = (
  numberStr: string,
  maxDecimals: number,
): string => {
  const parts = numberStr.split(".")

  if (parts.length === 2) {
    const integerPart = parts[0]
    const decimalPart = parts[1].slice(0, maxDecimals)

    return `${integerPart}.${decimalPart}`
  } else {
    return numberStr
  }
}

export const getNumberLabelFromBigNumber = (
  value: string,
  decimals: number,
): string => {
  const valueBigNumber = BigNumber.from(value).div((10 ** decimals).toString())
  const valueLabel = new Intl.NumberFormat("en-US").format(
    Number(valueBigNumber),
  )

  return valueLabel
}
