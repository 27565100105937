import React, { useState, useEffect } from "react"
import {
  IconButton,
  Icon,
  Text,
  Link,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import { ChevronLeftIcon } from "@chakra-ui/icons"
import { isMobile, isChrome } from "react-device-detect"
import { useConnect } from "wagmi"

import { useConnectWallet } from "web3/hooks/useConnectWallet"
import WalletConnectIcon from "web3/components/icons/WalletConnectIcon"
import MetaMaskIcon from "web3/components/icons/MetaMaskIcon"
import RabbyIcon from "web3/components/icons/RabbyIcon"
import BraveIcon from "web3/components/icons/BraveIcon"
import PhantomIcon from "web3/components/icons/PhantomIcon"
import { Globe } from "ui/components/icons/font-awesome/Globe"
import WalletConnectChainSelector from "web3/components/WalletConnectChainSelector"
import WalletButton from "web3/components/WalletButton"
import CoinbaseWalletIcon from "web3/components/icons/CoinbaseWalletIcon"
import { ConnectorId, hasConnectorSupport } from "web3/constants/connectors"
import { useLocation } from "common/hooks/useLocation"
import { EXTERNAL_ROUTES } from "common/constants/routes"

function WalletSelectionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const {
    activateBraveWallet,
    activateMetamask,
    activateRabby,
    activateCoinbaseWallet,
    activateGenericInjected,
  } = useConnectWallet()
  const { connectors } = useConnect()
  const { href } = useLocation()
  const [hasMetaMask, setHasMetaMask] = useState(false)
  const [hasRabby, setHasRabby] = useState(false)
  const [hasBraveWallet, setHasBraveWallet] = useState(false)
  const [hasPhantomWallet, setHasPhantomWallet] = useState(false)
  const [hasGenericWallet, setHasGenericWallet] = useState(false)
  const [
    shouldShowWalletConnectConnectors,
    setShouldShowWalletConnectConnectors,
  ] = useState(false)

  const shouldShowWalletConnect = !(
    isMobile &&
    isChrome &&
    typeof window !== undefined &&
    window?.ethereum?.isMetaMask
  )

  const shouldShowMetaMaskOption =
    hasMetaMask && hasConnectorSupport(connectors, ConnectorId.MetaMask)

  const shouldShowRabbyOption =
    hasRabby &&
    isChrome && // Rabby is only a chrome extension
    !isMobile && // there is no mobile version of Rabby yet
    hasConnectorSupport(connectors, ConnectorId.Injected) // Rabby is an injected connector

  const shouldShowCoinbaseWalletOption = hasConnectorSupport(
    connectors,
    ConnectorId.CoinbaseWallet,
  )

  const shouldShowBraveWallet =
    hasBraveWallet && hasConnectorSupport(connectors, ConnectorId.Injected) // Brave Wallet is an injected connector

  const shouldShowPhantomWallet =
    hasPhantomWallet && hasConnectorSupport(connectors, ConnectorId.Injected)

  const shouldShowGenericWallet =
    hasGenericWallet && hasConnectorSupport(connectors, ConnectorId.Injected)

  useEffect(() => {
    if (
      typeof window !== undefined &&
      // only `isMetaMask` is present when Rabby is set to "Metamask is in use and Rabby is banned"
      window?.ethereum?.isMetaMask &&
      !window?.ethereum?.isRabby
    ) {
      setHasMetaMask(true)
    }

    if (
      typeof window !== undefined &&
      // both of these properties are present when Rabby is set to "Rabby is in use and Metamask is banned"
      window?.ethereum?.isMetaMask &&
      window?.ethereum?.isRabby
    ) {
      setHasRabby(true)
    }

    if (typeof window !== undefined && window?.ethereum?.isBraveWallet) {
      setHasBraveWallet(true)
    }

    if (typeof window !== undefined && window?.ethereum?.isPhantom) {
      setHasPhantomWallet(true)
    }

    if (
      typeof window !== undefined &&
      !window?.ethereum?.isMetaMask &&
      !window?.ethereum?.isBraveWallet &&
      !window?.ethereum?.isRabby
    ) {
      setHasGenericWallet(true)
    }
  }, [])

  const displayDeepLink = !hasMetaMask && isMobile

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setShouldShowWalletConnectConnectors(false)
        onClose()
      }}
    >
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent maxWidth={408} top={{ base: "unset", lg: "60px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          Connect Wallet
        </ModalHeader>
        {shouldShowWalletConnectConnectors ? (
          <IconButton
            aria-label="Back"
            icon={<ChevronLeftIcon height={6} width={6} />}
            position="absolute"
            right={4}
            size="sm"
            top={4}
            variant="ghost"
            onClick={() => setShouldShowWalletConnectConnectors(false)}
          />
        ) : (
          <ModalCloseButton right={4} top={4} />
        )}
        <ModalBody p={4}>
          {shouldShowWalletConnectConnectors ? (
            <WalletConnectChainSelector
              onClick={() => {
                setShouldShowWalletConnectConnectors(false)
                onClose()
              }}
            />
          ) : (
            <Stack spacing={4}>
              <Text textStyle="md">
                Please select a wallet to connect to Tally:
              </Text>
              <Stack spacing={2}>
                {(shouldShowMetaMaskOption &&
                  !shouldShowRabbyOption &&
                  !shouldShowBraveWallet &&
                  !shouldShowGenericWallet) ||
                displayDeepLink ? (
                  <WalletButton
                    icon={<MetaMaskIcon height={8} width={8} />}
                    walletName="MetaMask"
                    onClick={() => {
                      if (displayDeepLink) {
                        window.open(
                          EXTERNAL_ROUTES.mobileDeepLink.metamask(href),
                          "_blank",
                        )

                        return
                      }

                      activateMetamask()
                      onClose()
                    }}
                  />
                ) : null}

                {shouldShowPhantomWallet ? (
                  <WalletButton
                    icon={<PhantomIcon height={8} width={8} />}
                    walletName="Phantom"
                    onClick={() => {
                      activateGenericInjected()
                      onClose()
                    }}
                  />
                ) : null}

                {shouldShowBraveWallet ? (
                  <WalletButton
                    icon={<BraveIcon height={8} width={8} />}
                    walletName="Brave Wallet"
                    onClick={() => {
                      activateBraveWallet()
                      onClose()
                    }}
                  />
                ) : null}

                {shouldShowRabbyOption ? (
                  <WalletButton
                    icon={<RabbyIcon height={8} width={8} />}
                    walletName="Rabby"
                    onClick={() => {
                      activateRabby()
                      onClose()
                    }}
                  />
                ) : null}

                {shouldShowGenericWallet ? (
                  <WalletButton
                    icon={<Icon as={Globe} h={6} w={6} />}
                    walletName="Browser wallet"
                    onClick={() => {
                      activateGenericInjected()
                      onClose()
                    }}
                  />
                ) : null}

                {shouldShowWalletConnect ? (
                  <WalletButton
                    icon={<WalletConnectIcon height={8} width={8} />}
                    walletName="WalletConnect"
                    onClick={() => {
                      setShouldShowWalletConnectConnectors(true)
                    }}
                  />
                ) : null}

                {shouldShowCoinbaseWalletOption || displayDeepLink ? (
                  <WalletButton
                    icon={<CoinbaseWalletIcon height={8} width={8} />}
                    walletName="Coinbase Wallet"
                    onClick={() => {
                      if (displayDeepLink) {
                        window.open(
                          EXTERNAL_ROUTES.mobileDeepLink.coinbaseWallet(href),
                          "_blank",
                        )

                        return
                      }

                      activateCoinbaseWallet()
                      onClose()
                    }}
                  />
                ) : null}
              </Stack>
              <Text>
                Using a Safe? See our{" "}
                <Link
                  isExternal
                  color="purple.700"
                  href={EXTERNAL_ROUTES.tally.docs.signInAsSafe()}
                >
                  guide
                </Link>
                .
              </Text>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-start" p={4}>
          <Text mr={2} textStyle="md">
            {`Don't have a wallet?`}
          </Text>
          <Link isExternal href="https://ethereum.org/en/wallets">
            <Text fontWeight="bold" textStyle="md">
              Learn more
            </Text>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

// eslint-disable-next-line import/no-unused-modules
export default WalletSelectionModal
