import type { FC, LegacyRef, ReactNode } from "react"
import { useMemo } from "react"
import type { FlexProps } from "@chakra-ui/react"
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Stack,
  Text,
  useDisclosure,
  Divider,
  Badge,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import isEqual from "lodash.isequal"
import { useAccount } from "wagmi"

import {
  OrganizationsSortBy,
  useExploreOrgsQuery,
  useUserOrganizationsMemberOfQuery,
  type Organization,
} from "query/graphql"
import Link from "common/components/Link"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"
import TallyBlackT from "ui/components/icons/tally/TallyBlackT"
import { useDevice } from "common/hooks/useDevice"
import { PeopleGroup } from "ui/components/icons/font-awesome/PeopleGroup"
import { BuildingColumns } from "ui/components/icons/font-awesome/BuildingColumns"
import { SquarePollHorizontal } from "ui/components/icons/font-awesome/SquarePollHorizontal"
import { Bars } from "ui/components/icons/font-awesome/Bars"
import { Gear } from "ui/components/icons/font-awesome/Gear"
import { Code } from "ui/components/icons/font-awesome/Code"
import { MagnifyingGlass } from "ui/components/icons/font-awesome/MagnifyingGlass"
import { Plus } from "ui/components/icons/font-awesome/Plus"
import HomeIcon from "ui/components/icons/HomeIcon"
import { Books } from "ui/components/icons/font-awesome/Books"
import { Download } from "ui/components/icons/font-awesome/Download"
import ContractsModal from "governance/components/ContractsModal"
import { Volume } from "ui/components/icons/font-awesome/Volume"
import { TALLY_DEFAULT_LOGO_URL } from "common/constants/logo"
import { subString } from "common/helpers/string"
import { useRouter } from "common/hooks/useRouter"
import HomeSolid from "ui/components/icons/font-awesome/HomeSolid"
import { getOrganizationFAQs } from "whitelabel/constants/faqs"
import { getOrganizationClaimHelpLinks } from "whitelabel/constants/helps"
import { CircleQuestion } from "ui/components/icons/font-awesome/CircleQuestion"
import PageOwnerAndEditorModal from "organization/components/OrganizationPageOwnerAndEditorModal"
import { useMe } from "user/providers/MeProvider"
import { Wallet } from "ui/components/icons/font-awesome/Wallet"
import { FileLines } from "ui/components/icons/font-awesome/FileLines"
import { MessageExclamation } from "ui/components/icons/font-awesome/MessageExclamation"

export enum OrganizationMenu {
  Home = "home",
  Proposals = "proposals",
  Community = "community",
  Stake = "stake",
  Treasury = "treasury",
  MyVotingPower = "my-voting-power",
  DelegateProfile = "delegate-profile",
  Settings = "settings",
  ContractsAndParams = "contracts-and-params",
  PageOwnerAndEditor = "page-owner-and-editor",
  FAQ = "faq",
  More = "more",
}

type Disclosure = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

type Props = {
  organization: Organization
  isWhiteLabel: boolean
  searchDaosToastDisclousure?: Disclosure
  tallyMenuDisclousure?: Disclosure
  tallyMenuRef?: LegacyRef<HTMLButtonElement>
}
export const HeaderTopLeftDAO: FC<Props> = ({
  organization,
  isWhiteLabel,
  searchDaosToastDisclousure,
  tallyMenuDisclousure,
  tallyMenuRef,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { asPath, reload } = useRouter()

  return (
    <>
      <Stack align="center" direction="row" display={onLargeDevice} h="42px">
        {!isWhiteLabel ? (
          <>
            <TallyLogoMenu display={onLargeDevice} />

            <DAODropdownMenu
              organization={organization}
              searchDaosToastDisclousure={searchDaosToastDisclousure}
            />
          </>
        ) : null}

        {isWhiteLabel ? (
          <Link
            _hover={{
              cursor: "pointer",
            }}
            className="no-underline"
            href={ROUTES.governance.id(organization.slug)}
            onClick={(e) => {
              // This fix a Next.js issue when a Link navigates to the same page
              if (
                asPath &&
                isEqual(
                  asPath.toLowerCase(),
                  ROUTES.governance.id(organization.slug).toLowerCase(),
                )
              ) {
                e.preventDefault()
                e.stopPropagation()
                reload()
              }
            }}
          >
            <Box
              _hover={{
                borderColor: "gray.400",
              }}
              borderColor="gray.200"
              borderRadius="lg"
              borderWidth="1px"
              height="44px"
            >
              <DAOHeader organization={organization} />
            </Box>
          </Link>
        ) : null}
      </Stack>
      <Stack
        align="center"
        direction="row"
        display={onLittleDevice}
        h="42px"
        ml="-10px"
      >
        {isWhiteLabel ? (
          <DAOHeader organization={organization} />
        ) : (
          <Button
            ref={tallyMenuRef}
            variant="transparent"
            onClick={() => tallyMenuDisclousure?.onOpen()}
          >
            <Stack align="center" direction="row" spacing={0}>
              <Icon as={TallyBlackT} h="full" w={5} />
              <Divider height="36px" ml={2} orientation="vertical" />
              <DAOHeader organization={organization} />
            </Stack>
          </Button>
        )}
      </Stack>
    </>
  )
}

type DAOHeaderProps = {
  organization: Organization
}
const DAOHeader: FC<DAOHeaderProps> = ({ organization }) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  return (
    <Stack
      align="center"
      direction="row"
      height="44px"
      pl={2}
      pr={4}
      spacing={1}
    >
      {organization?.metadata?.icon ? (
        <Image
          alt="DAO logo"
          height="auto"
          placeholder="blur"
          rounded="full"
          src={organization?.metadata?.icon ?? TALLY_DEFAULT_LOGO_URL}
          width="23px"
        />
      ) : null}
      <Text display={onLargeDevice} fontSize="16px" fontWeight="bold">
        {subString(organization.name, 20)}
      </Text>
      <Text display={onLittleDevice} fontSize="18px" fontWeight="bold">
        {subString(organization.name, 15)}
      </Text>
    </Stack>
  )
}

type DAODropdownMenuProps = {
  organization: Organization
  searchDaosToastDisclousure?: Disclosure
}
const DAODropdownMenu: FC<DAODropdownMenuProps & FlexProps> = ({
  organization,
  searchDaosToastDisclousure,
  ...flexProps
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { address: walletAddress } = useAccount()

  const me = useMe()

  const address = me?.address ? me.address : walletAddress

  const routes = [
    {
      label: "Search DAOs",
      disclosure: searchDaosToastDisclousure,
      icon: <Icon as={MagnifyingGlass} h={4} w={4} />,
    },
  ]

  const { data } = useUserOrganizationsMemberOfQuery(
    {
      input: {
        filters: {
          isMember: true,
          address: address,
        },
        sort: {
          isDescending: false,
          sortBy: OrganizationsSortBy.Name,
        },
      },
    },
    {
      enabled: Boolean(address),
    },
  )

  const { data: popularData } = useExploreOrgsQuery(
    {
      input: {
        sort: {
          isDescending: true,
          sortBy: OrganizationsSortBy.Popular,
        },
        filters: {
          hasLogo: true,
        },
        page: { limit: 10 },
      },
    },
    {
      enabled: !Boolean(address),
    },
  )

  const organizations = useMemo(() => {
    const originalOrganizations = Boolean(address)
      ? (data?.organizations.nodes as Organization[])
      : (popularData?.organizations.nodes as Organization[])

    if (!originalOrganizations) return []

    return originalOrganizations
      .filter(
        (org) => !isEqual(org.id.toLowerCase(), organization.id.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.hasActiveProposals) {
          return -1
        }

        if (b.hasActiveProposals) {
          return 1
        }

        return 0
      })
  }, [
    address,
    data?.organizations.nodes,
    organization.id,
    popularData?.organizations.nodes,
  ])

  const hasOrganizations = organizations && organizations.length > 0
  const maxOrganizationsToDisplay = 6

  return (
    <Flex zIndex={1000} {...flexProps}>
      <Menu>
        <MenuButton
          _hover={{
            borderColor: "gray.400",
            color: "gray.700",
            cursor: "pointer",
            borderRadius: "lg",
          }}
          as={Button}
          bgColor="white"
          borderColor="gray.200"
          borderRadius="lg"
          borderWidth="1px"
          color="gray.500"
          fontWeight="medium"
          height="44px"
          my={1}
          px={3}
          py={1}
        >
          <Stack align="center" direction="row">
            <Image
              alt="DAO logo"
              height="auto"
              placeholder="blur"
              rounded="full"
              src={organization?.metadata?.icon ?? TALLY_DEFAULT_LOGO_URL}
              width="23px"
            />
            <Text
              color="gray.800"
              display={onLargeDevice}
              fontSize="16px"
              fontWeight="bold"
            >
              {subString(organization.name, 20)}
            </Text>
            <Text
              color="gray.800"
              display={onLittleDevice}
              fontSize="16px"
              fontWeight="bold"
            >
              {subString(organization.name, 10)}
            </Text>
          </Stack>
        </MenuButton>
        <MenuList zIndex={900}>
          {hasOrganizations ? (
            <Stack>
              <Text
                color="gray.700"
                fontSize="sm"
                fontWeight="bold"
                mt={2}
                mx={4}
              >
                {address ? "Your DAOs" : "Popular DAOs"}
              </Text>
              {organizations.slice(0, maxOrganizationsToDisplay).map((org) => (
                <MenuItem
                  key={`org-${org.id}`}
                  as={Link}
                  className="no-underline"
                  href={ROUTES.governance.id(org.slug)}
                  px={1}
                  py={0}
                >
                  <Stack
                    _hover={{ bgColor: "gray.100", color: "black" }}
                    align="center"
                    bgColor="auto"
                    borderRadius="lg"
                    color="gray.900"
                    direction="row"
                    fontSize="sm"
                    fontWeight="medium"
                    px={4}
                    py={2}
                    width="100%"
                  >
                    <Image
                      alt={`${org.name} DAO logo`}
                      height="auto"
                      placeholder="blur"
                      rounded="full"
                      src={org?.metadata?.icon ?? TALLY_DEFAULT_LOGO_URL}
                      width="23px"
                    />
                    <Text display={onLargeDevice}>
                      {subString(org.name, 20)}
                    </Text>
                    <Text display={onLittleDevice}>
                      {subString(org.name, 15)}
                    </Text>
                    {org.hasActiveProposals ? (
                      <Badge
                        borderRadius="sm"
                        colorScheme="purple"
                        mb={0.5}
                        ml={1}
                        px={1}
                      >
                        <Text
                          color="purple.600"
                          fontWeight="medium"
                          textStyle="xs"
                          textTransform="none"
                        >
                          Active proposal
                        </Text>
                      </Badge>
                    ) : null}
                  </Stack>
                </MenuItem>
              ))}

              {organizations.length > maxOrganizationsToDisplay ? (
                <MenuItem
                  as={Link}
                  className="no-underline"
                  href={address ? ROUTES.user.yourDAOs() : ROUTES.explore()}
                  px={1.5}
                  py={0}
                >
                  <Button variant="secondary" width="100%">
                    View all
                  </Button>
                </MenuItem>
              ) : null}

              <Divider mb={2} />
            </Stack>
          ) : null}

          {routes.map((route) => (
            <MenuItem
              key={route.label}
              className="no-underline"
              px={1.5}
              py={0}
              onClick={
                route?.disclosure ? route?.disclosure?.onOpen : undefined
              }
            >
              <HStack
                _hover={{ bgColor: "gray.100", color: "black" }}
                align="center"
                bgColor="auto"
                borderRadius="lg"
                color="gray.900"
                fontSize="sm"
                fontWeight="medium"
                px={4}
                py={2}
                width="100%"
              >
                {route.icon ? (
                  <Box _hover={{ color: "gray.900" }} color="gray.500">
                    {route.icon}
                  </Box>
                ) : null}
                <Text pt={1}>{route.label}</Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

type HeaderLinksDAOProps = {
  organization: Organization
  isWhiteLabel: boolean
  activeMenu?: OrganizationMenu
}
export const HeaderLinksDAO: FC<HeaderLinksDAOProps> = ({
  organization,
  isWhiteLabel,
  activeMenu,
}) => {
  const { routes, moreRoutes } = useOrganizationNavMenu({
    organization,
    activeMenu,
    isWhiteLabel,
  })

  const hasRoutes = routes.length > 0 || moreRoutes.length > 0

  if (!hasRoutes) {
    return null
  }

  return (
    <>
      <Stack
        align="center"
        direction={{ base: "column", md: "row" }}
        h="44px"
        my={1.2}
        px={1}
      >
        {routes.map((route) => (
          <Link key={route.url} className="no-underline" href={route.url}>
            <HStack
              _hover={{ bgColor: "gray.100", color: "gray.800" }}
              align="center"
              bgColor={route.isActive ? "gray.100" : "auto"}
              borderRadius="lg"
              color={route.isActive ? "black" : "gray.900"}
              fontWeight={route.isActive ? "bold" : "medium"}
              h="36px"
              px={4}
              textStyle="sm"
            >
              <HStack align="center">
                {route.icon ? (
                  <Flex
                    as="span"
                    color={route.isActive ? "gray.800" : "gray.500"}
                  >
                    {route.icon}
                  </Flex>
                ) : null}
                <Text>{route.label}</Text>
              </HStack>
            </HStack>
          </Link>
        ))}

        {moreRoutes.length > 0 ? (
          <Menu>
            <MenuButton
              _hover={{
                bgColor: "gray.100",
                color: "gray.900",
                cursor: "pointer",
              }}
              borderRadius="lg"
              color="gray.900"
              fontWeight="medium"
              h="36px"
              my={1}
              px={4}
              textStyle="sm"
              zIndex={900}
            >
              <HStack align="center">
                <Flex>
                  <Icon as={Bars} color="gray.500" h={4} w={4} />
                </Flex>
                <Text color="gray.700">More</Text>
              </HStack>
            </MenuButton>
            <MenuList zIndex={900}>
              {moreRoutes.map((route, idx) => (
                <MenuItem
                  key={`more-routes-${idx}`}
                  as={route.url ? Link : undefined}
                  className="no-underline"
                  href={route.url}
                  px={1.5}
                  py={0}
                  onClick={
                    route.disclosure ? route?.disclosure?.onOpen : undefined
                  }
                >
                  <HStack
                    _hover={{ bgColor: "gray.100", color: "black" }}
                    align="center"
                    bgColor={route.isActive ? "gray.100" : "auto"}
                    borderRadius="lg"
                    color={route.isActive ? "black" : "gray.900"}
                    fontSize="sm"
                    fontWeight="medium"
                    px={4}
                    py={2}
                    width="100%"
                  >
                    <HStack align="center">
                      {route.icon ? (
                        <Flex color={route.isActive ? "gray.800" : "gray.500"}>
                          {route.icon}
                        </Flex>
                      ) : null}
                      <Text>{route.label}</Text>
                    </HStack>
                  </HStack>
                  {route.modal ? <>{route.modal}</> : null}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : null}
      </Stack>
    </>
  )
}

type DAONavMenu = {
  menu: OrganizationMenu
  label: string
  url?: string
  disclosure?: ReturnType<typeof useDisclosure>
  modal?: ReactNode
  isActive?: boolean
  icon?: ReactNode
}

const useOrganizationNavMenu = ({
  organization,
  activeMenu,
  isWhiteLabel,
}: {
  organization: Organization
  activeMenu?: OrganizationMenu
  isWhiteLabel: boolean
}): { routes: DAONavMenu[]; moreRoutes: DAONavMenu[] } => {
  const contractsModalDisclosure = useDisclosure()
  const pageOwnerAndEditorModalDisclosure = useDisclosure()

  const { isAdminRole, isFeatureFlagOn } = useOrganization()

  const hasGovernors = organization?.governorIds?.length > 0

  const routes = useMemo(() => {
    if (!hasGovernors) {
      return [
        {
          menu: OrganizationMenu.Home,
          label: "Home",
          url: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(organization.slug))
            : ROUTES.governance.id(organization.slug),
          isActive: activeMenu === OrganizationMenu.Home,
          icon: <Icon as={HomeSolid} h={4} w={4} />,
        },
        {
          menu: OrganizationMenu.Community,
          label: "Community",
          url: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.delegates(organization.slug))
            : ROUTES.governance.delegates(organization.slug),
          isActive: activeMenu === OrganizationMenu.Community,
          icon: <Icon as={PeopleGroup} h={4} w={4} />,
        },
        ...(isFeatureFlagOn(FeatureFlag.ShowUnistaker) ||
        isFeatureFlagOn(FeatureFlag.ShowArbStaking)
          ? [
              {
                menu: OrganizationMenu.Stake,
                label: "Stake",
                url: isWhiteLabel
                  ? getWhiteLabelRoute(
                      ROUTES.governance.stake.index(organization.slug),
                    )
                  : ROUTES.governance.stake.index(organization.slug),
                isActive: activeMenu === OrganizationMenu.Stake,
                icon: <Icon as={Download} h={4} w={4} />,
              },
            ]
          : []),
      ]
    }

    return [
      {
        menu: OrganizationMenu.Home,
        label: "Home",
        url: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(organization.slug))
          : ROUTES.governance.id(organization.slug),
        isActive: activeMenu === OrganizationMenu.Home,
        icon: <Icon as={HomeSolid} h={4} w={4} />,
      },
      {
        menu: OrganizationMenu.Proposals,
        label: "Proposals",
        url: isWhiteLabel
          ? getWhiteLabelRoute(
              ROUTES.governance.proposals.list(organization.slug),
            )
          : ROUTES.governance.proposals.list(organization.slug),
        isActive: activeMenu === OrganizationMenu.Proposals,
        icon: <Icon as={SquarePollHorizontal} h={4} w={4} />,
      },
      {
        menu: OrganizationMenu.Community,
        label: "Community",
        url: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.delegates(organization.slug))
          : ROUTES.governance.delegates(organization.slug),
        isActive: activeMenu === OrganizationMenu.Community,
        icon: <Icon as={PeopleGroup} h={4} w={4} />,
      },
      ...(isFeatureFlagOn(FeatureFlag.ShowUnistaker) ||
      isFeatureFlagOn(FeatureFlag.ShowArbStaking)
        ? [
            {
              menu: OrganizationMenu.Stake,
              label: "Stake",
              url: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.stake.index(organization.slug),
                  )
                : ROUTES.governance.stake.index(organization.slug),
              isActive: activeMenu === OrganizationMenu.Stake,
              icon: <Icon as={Download} h={4} w={4} />,
            },
          ]
        : []),
      ...(!isFeatureFlagOn(FeatureFlag.HideTreasury)
        ? [
            {
              menu: OrganizationMenu.Treasury,
              label: "Treasury",
              url: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.treasury.list(organization.slug),
                  )
                : ROUTES.governance.treasury.list(organization.slug),
              isActive: activeMenu === OrganizationMenu.Treasury,
              icon: <Icon as={BuildingColumns} h={4} w={4} />,
            },
          ]
        : []),
    ]
  }, [
    activeMenu,
    hasGovernors,
    isFeatureFlagOn,
    isWhiteLabel,
    organization.slug,
  ])

  const moreRoutes = useMemo(() => {
    if (!hasGovernors) {
      return []
    }

    const adminRoute = isAdminRole
      ? [
          {
            menu: OrganizationMenu.Settings,
            label: "Admin settings",
            url: isWhiteLabel
              ? getWhiteLabelRoute(
                  ROUTES.governance.settings.index(organization.slug),
                )
              : ROUTES.governance.settings.index(organization.slug),
            icon: <Icon as={Gear} h={4} w={4} />,
          },
        ]
      : []

    const faqRoute =
      getOrganizationFAQs(organization.id)?.length > 0 ||
      getOrganizationClaimHelpLinks(organization.id)?.length > 0
        ? [
            {
              menu: OrganizationMenu.Settings,
              label: "FAQ",
              url: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.faq(organization.slug))
                : ROUTES.governance.faq(organization.slug),
              icon: <Icon as={CircleQuestion} h={4} w={4} />,
            },
          ]
        : []

    return [
      ...adminRoute,
      ...faqRoute,
      {
        menu: OrganizationMenu.ContractsAndParams,
        label: "Contracts and parameters",
        disclosure: contractsModalDisclosure,
        modal: (
          <ContractsModal
            governanceIds={organization.governorIds}
            isOpen={contractsModalDisclosure.isOpen}
            onClose={contractsModalDisclosure.onClose}
          />
        ),
        icon: <Icon as={Code} h={4} w={4} />,
      },
      ...(organization.governorIds.length < 2
        ? [
            {
              menu: OrganizationMenu.PageOwnerAndEditor,
              label: "Page owner and editors",
              disclosure: pageOwnerAndEditorModalDisclosure,
              modal: (
                <PageOwnerAndEditorModal
                  isOpen={pageOwnerAndEditorModalDisclosure.isOpen}
                  organization={organization}
                  onClose={pageOwnerAndEditorModalDisclosure.onClose}
                />
              ),
              icon: <Icon as={PeopleGroup} h={4} w={4} />,
            },
          ]
        : []),
    ]
  }, [
    hasGovernors,
    isAdminRole,
    isWhiteLabel,
    organization,
    contractsModalDisclosure,
    pageOwnerAndEditorModalDisclosure,
  ])

  return { routes, moreRoutes }
}

type HeaderTopRightDAOProps = {
  organization: Organization
  isWhiteLabel: boolean
  activeMenu?: OrganizationMenu
  userMenuDisclousure?: Disclosure
  userMenuRef?: LegacyRef<HTMLButtonElement>
}
export const HeaderTopRightDAO: FC<HeaderTopRightDAOProps> = ({
  organization,
  isWhiteLabel,
  activeMenu,
  userMenuDisclousure,
  userMenuRef,
}) => {
  const { routes, moreRoutes } = useOrganizationNavMenu({
    organization,
    isWhiteLabel,
    activeMenu,
  })

  const allRoutes = [...routes, ...moreRoutes]

  const activeMenuLabel = allRoutes
    ? allRoutes.find((route) => route.isActive)?.label ?? "Menu"
    : undefined

  return (
    <>
      {allRoutes.length > 0 ? (
        <Menu>
          <MenuButton
            _hover={{
              bgColor: "gray.100",
              color: "gray.700",
              cursor: "pointer",
            }}
            as={Button}
            bgColor="white"
            borderRadius="lg"
            color="gray.900"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="medium"
            my={1}
            py={1}
            rightIcon={<Icon as={ChevronDownIcon} h={3} w={3} />}
          >
            {activeMenuLabel}
          </MenuButton>
          <MenuList zIndex={600}>
            {allRoutes.map((route, idx) => (
              <MenuItem
                key={`mobile-route-${idx}`}
                as={route.url ? Link : undefined}
                bgColor="white"
                className="no-underline"
                href={route.url}
                width="100%"
                onClick={
                  route?.disclosure ? route?.disclosure?.onOpen : undefined
                }
              >
                <HStack
                  _hover={{ bgColor: "gray.100", color: "gray.700" }}
                  bgColor={route.isActive ? "gray.100" : "white"}
                  borderRadius="lg"
                  color={route.isActive ? "black" : "gray.900"}
                  fontWeight="medium"
                  px={2}
                  py={3}
                  width="100%"
                >
                  {route.icon ? (
                    <Box color={route.isActive ? "gray.900" : "gray.500"}>
                      {route.icon}
                    </Box>
                  ) : null}
                  <>{route.label}</>
                  {route.modal ? <>{route.modal}</> : null}
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : null}

      <IconButton
        ref={userMenuRef}
        _focusVisible={{
          boxShadow: "none",
          borderColor: "transparent",
          outline: "3px solid hsla(216, 12%, 84%, 1)",
          outlineOffset: "2px",
        }}
        aria-label="Go to home page"
        bg="white"
        icon={<Icon as={Wallet} color="gray.700" h={5} w={5} />}
        p={1}
        onClick={() => userMenuDisclousure?.onOpen()}
      />
    </>
  )
}

const TallyLogoMenu: FC<FlexProps> = ({ ...flexProps }) => {
  const routes = [
    {
      label: "Tally home",
      url: ROUTES.explore(),
      icon: <Icon as={HomeIcon} h={4} w={4} />,
    },
    {
      label: "Add a DAO",
      url: ROUTES.getStarted.index(),
      icon: <Icon as={Plus} h={4} w={4} />,
    },
    {
      label: "Solutions",
      url: ROUTES.solutions(),
      icon: <Icon as={Books} h={4} w={4} />,
    },
    {
      label: "Docs",
      url: EXTERNAL_ROUTES.tally.docs.index(),
      isExternal: true,
      icon: <Icon as={FileLines} h={4} w={4} />,
    },
    {
      label: "Media",
      url: ROUTES.media(),
      icon: <Icon as={Volume} h={4} w={4} />,
    },
    {
      label: "Support",
      url: EXTERNAL_ROUTES.tally.support(),
      isExternal: true,
      icon: <Icon as={MessageExclamation} h={4} w={4} />,
    },
  ]

  return (
    <Flex zIndex={1000} {...flexProps}>
      <Menu>
        <MenuButton
          _hover={{
            borderColor: "gray.400",
            color: "gray.700",
            cursor: "pointer",
            borderRadius: "lg",
          }}
          as={Button}
          bgColor="white"
          borderColor="gray.200"
          borderRadius="lg"
          borderWidth="1px"
          color="gray.500"
          fontWeight="medium"
          height="44px"
          my={1}
          px={3}
          py={1}
        >
          <Icon as={TallyBlackT} h="full" w={6} />
        </MenuButton>
        <MenuList>
          {routes.map((route) => (
            <MenuItem
              key={route.label}
              as={route.url ? Link : undefined}
              className="no-underline"
              href={route.url}
              isExternal={route.isExternal ?? false}
              px={1.5}
              py={0}
            >
              <HStack
                _hover={{ bgColor: "gray.100", color: "black" }}
                align="center"
                bgColor="auto"
                borderRadius="lg"
                color="gray.900"
                fontSize="sm"
                fontWeight="medium"
                px={4}
                py={2}
                width="100%"
              >
                {route.icon ? (
                  <Box _hover={{ color: "gray.900" }} color="gray.500">
                    {route.icon}
                  </Box>
                ) : null}
                <Text pt={1}>{route.label}</Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}
