import { useCallback } from "react"
import { parseCookies, setCookie } from "nookies"

import { jsonParse, jsonStringify } from "common/helpers/serialization"
import type { FeatureFlags } from "common/types/featureflags"
import { FeatureFlag } from "common/types/featureflags"

type Values = {
  setFeatureFlag: (nextState: boolean, featureFlag: FeatureFlag) => void
  isFeatureFlagOn: (featureFlag: FeatureFlag) => boolean
}

const getFeatureFlags = (): FeatureFlags | undefined => {
  const cookies = parseCookies()

  if (!cookies.features) return undefined

  const featureFlags: FeatureFlags = jsonParse(cookies.features)

  return featureFlags
}

export const useFeatureFlag = (): Values => {
  const isFeatureFlagOn = useCallback((featureFlag: FeatureFlag): boolean => {
    switch (featureFlag) {
      case FeatureFlag.NewLayout: {
        const featureFlags = getFeatureFlags()
        const isNewLayout = featureFlags?.[FeatureFlag.NewLayout] ?? false

        return isNewLayout
      }
      case FeatureFlag.SolanaConnect: {
        const featureFlags = getFeatureFlags()
        const isSolanaConnect =
          featureFlags?.[FeatureFlag.SolanaConnect] ?? false

        return isSolanaConnect
      }
      default: {
        return false
      }
    }
  }, [])

  const setFeatureFlag = (
    nextState: boolean,
    featureFlag: FeatureFlag,
  ): void => {
    const featureFlags = getFeatureFlags()

    if (!featureFlags) {
      const nextFeatureFlags: FeatureFlags = {
        [featureFlag]: nextState,
      }

      setCookie(null, "features", jsonStringify(nextFeatureFlags))
    }

    const nextFeatureFlags: FeatureFlags = {
      ...featureFlags,
      [featureFlag]: nextState,
    }

    setCookie(null, "features", jsonStringify(nextFeatureFlags), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    })
  }

  return { isFeatureFlagOn, setFeatureFlag }
}
