import React, { useState, useEffect, type ReactNode } from "react"
import {
  IconButton,
  Icon,
  Text,
  Link,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
} from "@chakra-ui/react"
import { ChevronLeftIcon } from "@chakra-ui/icons"
import { isMobile, isChrome } from "react-device-detect"
import { useConnect } from "wagmi"
import { toLower } from "lodash"
import { useWallet } from "@solana/wallet-adapter-react"
import type { WalletName } from "@solana/wallet-adapter-base"

import { useConnectWallet } from "web3/hooks/useConnectWallet"
import WalletConnectIcon from "web3/components/icons/WalletConnectIcon"
import MetaMaskIcon from "web3/components/icons/MetaMaskIcon"
import RabbyIcon from "web3/components/icons/RabbyIcon"
import BraveIcon from "web3/components/icons/BraveIcon"
import { Globe } from "ui/components/icons/font-awesome/Globe"
import WalletConnectChainSelector from "web3/components/WalletConnectChainSelector"
import WalletButton from "web3/components/WalletButton"
import CoinbaseWalletIcon from "web3/components/icons/CoinbaseWalletIcon"
import { ConnectorId, hasConnectorSupport } from "web3/constants/connectors"
import { useLocation } from "common/hooks/useLocation"
import { EXTERNAL_ROUTES } from "common/constants/routes"
import { FeatureFlag } from "common/types/featureflags"
import { multichainWallets } from "web3/constants/wallets"
import { useEIP6963Store } from "web3/stores/eip6963"
import { useFeatureFlag } from "common/hooks/useFeatureFlag"
import EthereumNetwork from "ui/components/icons/EthereumNetwork"
import SolanaNetwork from "ui/components/icons/SolanaNetwork"

type Wallet = {
  name: string
  connectorId?: string
  icon: string | ReactNode
  isMultichainWallet: boolean
  isWalletConnect?: boolean
  isEVM?: boolean
  isSOL?: boolean
  sol?: Record<string, any>
}

type MobileWallet = {
  name: string
  connectorId: string
  icon: string | ReactNode
  isMultichainWallet: boolean
  isWalletConnect?: boolean
  isEVM?: boolean
  isSOL?: boolean
}

function WalletSelectionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const eip6963Store = useEIP6963Store()
  const { activateEVMWallet, activateSolanaWallet } = useConnectWallet()
  const {
    activateBraveWallet,
    activateMetamask,
    activateRabby,
    activateCoinbaseWallet,
    activateGenericInjected,
  } = useConnectWallet()
  const { connectors } = useConnect()
  const { wallets: sWallets } = useWallet()
  const { isFeatureFlagOn } = useFeatureFlag()
  const { href } = useLocation()
  const [hasMetaMask, setHasMetaMask] = useState(false)
  const [hasRabby, setHasRabby] = useState(false)
  const [hasBraveWallet, setHasBraveWallet] = useState(false)
  const [hasGenericWallet, setHasGenericWallet] = useState(false)
  const [
    shouldShowWalletConnectConnectors,
    setShouldShowWalletConnectConnectors,
  ] = useState(false)
  const [walletGroup, setWalletGroup] = useState<Wallet | MobileWallet | null>(
    null,
  )
  const [showWalletConnectOptions, setShowWalletConnectOptions] =
    useState(false)

  const shouldShowWalletConnect = !(
    isMobile &&
    isChrome &&
    typeof window !== undefined &&
    window?.ethereum?.isMetaMask
  )

  const shouldShowMetaMaskOption =
    hasMetaMask && hasConnectorSupport(connectors, ConnectorId.MetaMask)

  const shouldShowRabbyOption =
    hasRabby &&
    isChrome && // Rabby is only a chrome extension
    !isMobile && // there is no mobile version of Rabby yet
    hasConnectorSupport(connectors, ConnectorId.Injected) // Rabby is an injected connector

  const shouldShowCoinbaseWalletOption = hasConnectorSupport(
    connectors,
    ConnectorId.CoinbaseWallet,
  )

  const shouldShowBraveWallet =
    hasBraveWallet && hasConnectorSupport(connectors, ConnectorId.Injected) // Brave Wallet is an injected connector

  const shouldShowGenericWallet =
    hasGenericWallet && hasConnectorSupport(connectors, ConnectorId.Injected)

  useEffect(() => {
    if (
      typeof window !== undefined &&
      // only `isMetaMask` is present when Rabby is set to "Metamask is in use and Rabby is banned"
      window?.ethereum?.isMetaMask &&
      !window?.ethereum?.isRabby
    ) {
      setHasMetaMask(true)
    }

    if (
      typeof window !== undefined &&
      // both of these properties are present when Rabby is set to "Rabby is in use and Metamask is banned"
      window?.ethereum?.isMetaMask &&
      window?.ethereum?.isRabby
    ) {
      setHasRabby(true)
    }

    if (typeof window !== undefined && window?.ethereum?.isBraveWallet) {
      setHasBraveWallet(true)
    }

    if (
      typeof window !== undefined &&
      !window?.ethereum?.isMetaMask &&
      !window?.ethereum?.isBraveWallet &&
      !window?.ethereum?.isRabby
    ) {
      setHasGenericWallet(true)
    }
  }, [])

  const displayDeepLink = !hasMetaMask && isMobile

  // checks all wallets detected with eip6963 and creates new wallet group that
  // exists on evm and sol
  const walletGroups: Wallet[] = eip6963Store
    .filter((p) => {
      const eip6963Key = toLower(p.info.name.split(" ")[0])

      return Boolean(multichainWallets[eip6963Key])
    })
    .map((p) => {
      const connectorId = connectors.find(
        (connector) =>
          connector.name === p.info.rdns || connector.name === p.info.name,
      )?.id
      const eip6963Key = toLower(p.info.name.split(" ")[0])
      const multichainWalletKey = multichainWallets[eip6963Key]?.key

      return {
        name: p.info.name,
        connectorId,
        sol: {
          provider: getWindowProperty(
            multichainWallets[multichainWalletKey].sol.windowLocator[0],
          ),
        },
        icon: p.info.icon,
        isMultichainWallet: true,
      }
    })

  // if the wallet exists in multichain wallets config, we will discard it and
  // use the multichain wallet config as the source of truth.
  const solanaWallets: Wallet[] = sWallets
    .filter((sw) => {
      const key = toLower(sw.adapter.name).split(" ")[0]

      return !Boolean(multichainWallets[key])
    })
    .map((p) => ({
      name: p.adapter.name as string,
      icon: p.adapter.icon,
      isMultichainWallet: false,
      isSOL: true,
    }))

  const wallets = isFeatureFlagOn(FeatureFlag.SolanaConnect)
    ? ([] as Wallet[]).concat(solanaWallets).concat(walletGroups)
    : []

  async function handleItemClick(wallet: Wallet) {
    if (wallet.isMultichainWallet) {
      setWalletGroup(wallet)

      return
    }

    if (wallet.isWalletConnect) {
      setShowWalletConnectOptions(true)

      return
    }

    if (wallet.isEVM) {
      activateEVMWallet(wallet?.connectorId)
    }

    if (wallet.isSOL) {
      activateSolanaWallet(wallet.name as WalletName<string>)
    }

    handleClose()
  }

  function handleClose() {
    if (walletGroup !== null) {
      setWalletGroup(null)
    }

    if (showWalletConnectOptions) {
      setShowWalletConnectOptions(false)
    }

    onClose()
  }

  const mobileWallets = [] as Wallet[]

  function handleMobileItemClick(wallet: Wallet) {
    const displayDeepLink =
      typeof window !== undefined && !window?.ethereum?.isMetaMask

    if (wallet.connectorId === ConnectorId.MetaMask) {
      if (displayDeepLink) {
        window.open(EXTERNAL_ROUTES.mobileDeepLink.metamask(href), "_blank")

        return
      }

      activateEVMWallet(ConnectorId.MetaMask)
      handleClose()
    }

    if (wallet.connectorId === ConnectorId.CoinbaseWallet) {
      if (displayDeepLink) {
        window.open(
          EXTERNAL_ROUTES.mobileDeepLink.coinbaseWallet(href),
          "_blank",
        )

        return
      }

      activateEVMWallet(ConnectorId.CoinbaseWallet)
      handleClose()
    }

    if (wallet.connectorId === ConnectorId.WalletConnect) {
      setShowWalletConnectOptions(true)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setShouldShowWalletConnectConnectors(false)
        onClose()
      }}
    >
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent maxWidth={408} top={{ base: "unset", lg: "60px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          Connect Wallet
        </ModalHeader>
        {shouldShowWalletConnectConnectors ? (
          <IconButton
            aria-label="Back"
            icon={<ChevronLeftIcon height={6} width={6} />}
            position="absolute"
            right={4}
            size="sm"
            top={4}
            variant="ghost"
            onClick={() => setShouldShowWalletConnectConnectors(false)}
          />
        ) : null}
        <ModalBody p={4}>
          {!walletGroup && !showWalletConnectOptions ? (
            <ModalCloseButton right={4} top={4} />
          ) : null}

          {walletGroup && !showWalletConnectOptions ? (
            <IconButton
              aria-label="Back"
              icon={<ChevronLeftIcon height={6} width={6} />}
              position="absolute"
              right={4}
              size="sm"
              top={4}
              variant="ghost"
              onClick={() => {
                setWalletGroup(null)
              }}
            />
          ) : null}
          {showWalletConnectOptions && !walletGroup ? (
            <IconButton
              aria-label="Back"
              icon={<ChevronLeftIcon height={6} width={6} />}
              position="absolute"
              right={4}
              size="sm"
              top={4}
              variant="ghost"
              onClick={() => {
                setShowWalletConnectOptions(false)
              }}
            />
          ) : null}

          {shouldShowWalletConnectConnectors ? (
            <WalletConnectChainSelector
              onClick={() => {
                setShouldShowWalletConnectConnectors(false)
                onClose()
              }}
            />
          ) : (
            <Stack spacing={4}>
              {!walletGroup && !showWalletConnectOptions ? (
                <Text textStyle="md">
                  Please select a wallet to connect to Tally:
                </Text>
              ) : null}
              <Stack spacing={2}>
                {!walletGroup && !showWalletConnectOptions ? (
                  <>
                    {(shouldShowMetaMaskOption &&
                      !shouldShowRabbyOption &&
                      !shouldShowBraveWallet &&
                      !shouldShowGenericWallet) ||
                    displayDeepLink ? (
                      <WalletButton
                        icon={<MetaMaskIcon height={8} width={8} />}
                        walletName="MetaMask"
                        onClick={() => {
                          if (displayDeepLink) {
                            window.open(
                              EXTERNAL_ROUTES.mobileDeepLink.metamask(href),
                              "_blank",
                            )

                            return
                          }

                          activateMetamask()
                          onClose()
                        }}
                      />
                    ) : null}

                    {shouldShowBraveWallet ? (
                      <WalletButton
                        icon={<BraveIcon height={8} width={8} />}
                        walletName="Brave Wallet"
                        onClick={() => {
                          activateBraveWallet()
                          onClose()
                        }}
                      />
                    ) : null}

                    {shouldShowRabbyOption ? (
                      <WalletButton
                        icon={<RabbyIcon height={8} width={8} />}
                        walletName="Rabby"
                        onClick={() => {
                          activateRabby()
                          onClose()
                        }}
                      />
                    ) : null}

                    {shouldShowGenericWallet ? (
                      <WalletButton
                        icon={<Icon as={Globe} h={6} w={6} />}
                        walletName="Browser wallet"
                        onClick={() => {
                          activateGenericInjected()
                          onClose()
                        }}
                      />
                    ) : null}

                    {shouldShowWalletConnect ? (
                      <WalletButton
                        icon={<WalletConnectIcon height={8} width={8} />}
                        walletName="WalletConnect"
                        onClick={() => {
                          setShouldShowWalletConnectConnectors(true)
                        }}
                      />
                    ) : null}

                    {shouldShowCoinbaseWalletOption || displayDeepLink ? (
                      <WalletButton
                        icon={<CoinbaseWalletIcon height={8} width={8} />}
                        walletName="Coinbase Wallet"
                        onClick={() => {
                          if (displayDeepLink) {
                            window.open(
                              EXTERNAL_ROUTES.mobileDeepLink.coinbaseWallet(
                                href,
                              ),
                              "_blank",
                            )

                            return
                          }

                          activateCoinbaseWallet()
                          onClose()
                        }}
                      />
                    ) : null}
                  </>
                ) : null}

                <Stack>
                  {!walletGroup && !showWalletConnectOptions
                    ? !isMobile
                      ? wallets.map((w) => (
                          <WalletButton
                            key={w.connectorId}
                            icon={
                              <Stack>
                                {typeof w.icon === "string" ? (
                                  <Image
                                    alt=""
                                    borderRadius={8}
                                    height="auto"
                                    src={w.icon}
                                    width={8}
                                  />
                                ) : (
                                  w.icon
                                )}
                              </Stack>
                            }
                            walletName={w.name}
                            onClick={() => handleItemClick(w)}
                          />
                        ))
                      : mobileWallets.map((w) => (
                          <WalletButton
                            key={w.connectorId}
                            icon={
                              <Stack>
                                {typeof w.icon === "string" ? (
                                  <Image
                                    alt=""
                                    borderRadius={8}
                                    height="auto"
                                    src={w.icon}
                                    width={8}
                                  />
                                ) : (
                                  w.icon
                                )}
                              </Stack>
                            }
                            walletName={w.name}
                            onClick={() => handleMobileItemClick(w)}
                          />
                        ))
                    : null}
                  {walletGroup && !showWalletConnectOptions ? (
                    <WalletGroup
                      walletGroup={walletGroup}
                      onConnectEVM={(connectorId) => {
                        activateEVMWallet(connectorId)
                        handleClose()
                      }}
                      onConnectSolana={(walletName) => {
                        activateSolanaWallet(walletName)
                        handleClose()
                      }}
                    />
                  ) : null}
                  {showWalletConnectOptions && !walletGroup ? (
                    <WalletConnectChainSelector
                      onClick={() => {
                        setShowWalletConnectOptions(false)
                        onClose()
                      }}
                    />
                  ) : null}
                </Stack>
              </Stack>
              <Text>
                Are you using a Safe? See our{" "}
                <Link
                  isExternal
                  color="purple.700"
                  href={EXTERNAL_ROUTES.tally.docs.signInAsSafe()}
                >
                  guide
                </Link>
                .
              </Text>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-start" p={4}>
          <Text mr={2} textStyle="md">
            {`Don't have a wallet?`}
          </Text>
          <Link isExternal href="https://ethereum.org/en/wallets">
            <Text fontWeight="bold" textStyle="md">
              Learn more
            </Text>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

// eslint-disable-next-line import/no-unused-modules
export default WalletSelectionModal

function getWindowProperty(path: string): any {
  if (!path) {
    return
  }

  return path
    .split(".")
    .reduce(
      (obj, key) =>
        obj && typeof obj === "object" && key in obj ? obj[key] : undefined,
      window as any,
    )
}

type WalletGroupProps = {
  walletGroup: Wallet
  onConnectSolana: (walletName: WalletName<string>) => void
  onConnectEVM: (connectorId?: string) => void
}

const WalletGroup = ({
  walletGroup,
  onConnectSolana,
  onConnectEVM,
}: WalletGroupProps) => (
  <Stack>
    <Text>Select a network for {walletGroup.name} to connect to:</Text>
    <Stack>
      <WalletButton
        isDisabled
        icon={<Icon as={EthereumNetwork} height={8} width={8} />}
        walletName="EVM"
        onClick={() => onConnectEVM(walletGroup.connectorId)}
      />
      <WalletButton
        icon={<Icon as={SolanaNetwork} height={8} width={8} />}
        walletName="Solana"
        onClick={() => onConnectSolana(walletGroup.name as WalletName<string>)}
      />
    </Stack>
  </Stack>
)
