import { chainIdToChainReference } from "web3/helpers/transformers"
import { ChainId } from "web3/constants/chains"
import { getChainByReference } from "web3/helpers/findChain"

export const getChainReferenceName = (chainReference: number): string => {
  const foundChain = getChainByReference(chainReference)

  if (foundChain) {
    return foundChain.mediumName
  }

  return "unknown"
}

// Get Ethereum mainnet reference, in other words 1
export const getMainnetReference = (): number => {
  const ethereumMainnetChainId = getMainnetChainId()
  const mainnetReference: number = chainIdToChainReference(
    ethereumMainnetChainId,
  )

  return mainnetReference
}

// Get Ethereum mainnet chainId, in other words "eip155:1"
export const getMainnetChainId = (): string => {
  const ethereumMainnetChainId = ChainId.EthereumMainnet

  return ethereumMainnetChainId
}

// Get Ethereum Sepolia reference
export const getSepoliaReference = (): number => {
  const sepoliaChainId = getSepoliaChainId()
  const sepoliaReference: number = chainIdToChainReference(sepoliaChainId)

  return sepoliaReference
}

// Get Ethereum Sepolia chainId
export const getSepoliaChainId = (): string => {
  const sepoliaChainId = ChainId.EthereumTestnetSepolia

  return sepoliaChainId
}

// Get Arbitrum reference
export const getArbitrumReference = (): number => {
  const arbitrumChainId = getArbitrumChainId()
  const arbitrumReference: number = chainIdToChainReference(arbitrumChainId)

  return arbitrumReference
}

// Get Arbitrum Nova reference
export const getArbitrumNovaReference = (): number => {
  const arbitrumNovaChainId = ChainId.ArbitrumNova
  const arbitrumNovaReference: number =
    chainIdToChainReference(arbitrumNovaChainId)

  return arbitrumNovaReference
}

// Get Arbitrum chainId
export const getArbitrumChainId = (): string => {
  const arbitrumChainId = ChainId.ArbitrumOne

  return arbitrumChainId
}

// Get Arbitrum reference
export const getOptimismReference = (): number => {
  const optimismChainId = ChainId.Optimism
  const optimismReference: number = chainIdToChainReference(optimismChainId)

  return optimismReference
}

// Get Base Sepolia chainId
export const getBaseSepoliaChainId = (): string => {
  const chainId = ChainId.BaseSepoliaTestnet

  return chainId
}

// Get Arbitrum Sepolia chainId
export const getArbitrumSepoliaChainId = (): string => {
  const chainId = ChainId.ArbitrumSepoliaRollupTestnet

  return chainId
}

// Get Base Sepolia reference
export const getBaseSepoliaReference = (): number => {
  const chainId = getBaseSepoliaChainId()
  const chainReference: number = chainIdToChainReference(chainId)

  return chainReference
}
