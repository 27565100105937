import { Image } from "@chakra-ui/react"
import { useAccount } from "wagmi"
import { useWallet } from "@solana/wallet-adapter-react"

import MetaMaskIcon from "web3/components/icons/MetaMaskIcon"
import { ConnectorId } from "web3/constants/connectors"
import CoinbaseWalletIcon from "web3/components/icons//CoinbaseWalletIcon"
import WalletConnectIcon from "web3/components/icons/WalletConnectIcon"
import { useEIP6963Store } from "web3/stores/eip6963"
import { Plug } from "ui/components/icons/font-awesome/Plug"

const connectorIconMap = {
  [ConnectorId.MetaMask]: <MetaMaskIcon height={5} width={5} />,
  [ConnectorId.WalletConnect]: <WalletConnectIcon height={5} width={5} />,
  [ConnectorId.CoinbaseWallet]: <CoinbaseWalletIcon height={5} width={5} />,
}

const ConnectorIcon = () => {
  const eip6963Store = useEIP6963Store()
  const { connector } = useAccount()
  const { wallet } = useWallet()

  const connectorIcon = eip6963Store.find(
    (s) => s.info.rdns === connector?.name,
  )?.info.icon

  if (
    connector?.id === ConnectorId.MetaMask ||
    connector?.id === ConnectorId.WalletConnect ||
    connector?.id === ConnectorId.CoinbaseWallet
  ) {
    return connectorIconMap[connector.id]
  }

  if (Boolean(connectorIcon)) {
    return <Image alt="" height="auto" src={connectorIcon} width={5} />
  }

  if (Boolean(wallet?.adapter.icon)) {
    return <Image alt="" height="auto" src={wallet?.adapter.icon} width={4} />
  }

  return <Plug height={5} width={5} />
}

export default ConnectorIcon
